<template>
  <div class="services">
    <h1 class='container'>Services</h1>
    <div class='container content'>
      <div class='tiles'>
        <router-link v-for="(service, key) in services" :to="`/blog/pt-services/${key}`" :key="key" :class="service.color" class='tile'>
        
          <img src='/img/scale.png' />
          <h2>{{ service.title }}</h2>
          <div v-html="service.content"></div>
          <p>Read More</p>
        </router-link>
               
      </div>
      <div>
        <Contact />
      </div>
    </div>
  </div>
</template>

<script>
import Contact from '@/components/contact.vue'
export default {
  name: 'Services',
  components: { 
    Contact
  },
  computed: {
    services() {
      return this.$store.getters.services
    }
  }
}
</script>

<style scoped lang='scss'>
.services {
  padding-bottom: 50px;
  h1 {
    font-family: "Merriweather", serif;
    margin-bottom: 10px;
  }

  div.tiles {
  


  }


  h5 {
    margin: 0px;
    padding-bottom: 10px;
    font-weight: 300;
    color: #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
  }

  img {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
  }

  .content {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;

    div:nth-child(2) {
      div {
        border-radius: 5px;
        background-color: #2F3439;
        padding: 15px;
      }
    }
  }
}

</style>
